import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-sidebar-toggle"
export default class extends Controller {
  static targets = [ "sidebar", "sidebartwo" ]

  connect() {
  }

  toggle(e) {
    e.preventDefault()
    this.sidebarTarget.classList.toggle("hidden")
    this.sidebarTarget.classList.toggle("w-96")
    e.currentTarget.classList.toggle("bg-white")
    e.currentTarget.classList.toggle("text-white")
    e.currentTarget.classList.toggle("text-ggray-900")
  }

  toggletwo(e) {
    e.preventDefault()
    this.sidebartwoTarget.classList.toggle("hidden")
    this.sidebartwoTarget.classList.toggle("opacity-100")
    e.currentTarget.classList.toggle("bg-white")
    e.currentTarget.classList.toggle("text-white")
    e.currentTarget.classList.toggle("text-ggray-900")
  }
}
