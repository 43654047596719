import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-loader"
export default class extends Controller {
  connect() {
    this.loadImage()
  }

  loadImage() {
    const imageUrl = this.element.dataset.url // Replace this with actual URL if needed
    const img = new Image()

    img.onload = () => {
      this.element.src = imageUrl
    }

    img.onerror = () => {
      setTimeout(() => {
        this.loadImage()
      }, 1000) // Retry after 2 seconds
    }

    img.src = imageUrl
  }
}
