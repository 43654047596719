import { Controller } from "@hotwired/stimulus";

// toggle hide/show of a panel based on a click of a link
// content - an element that is hidden by default
// show - the link/button

export default class extends Controller {
  static targets = ["show", "content"];

  toggle(e) {
    e.preventDefault();

    if (this.showTarget.innerHTML == "Show") {
      this.contentTarget.classList.remove("hidden");
      this.showTarget.innerHTML = "Hide";
    } else {
      this.contentTarget.classList.add("hidden");
      this.showTarget.innerHTML = "Show";
    }
  }
}
