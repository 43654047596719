import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "logoColor",
    "activeColor",
    "backgroundColor",
    "hoverColor",
    "textColor",
    "panelColor"
  ];

  lightpreset(e) {
    e.preventDefault();
    this.logoColorTarget.value = "#636363";
    this.activeColorTarget.value = "#c5c5c5";
    this.backgroundColorTarget.value = "#ededed";
    this.hoverColorTarget.value = "#c5c5c5";
    this.textColorTarget.value = "#333333";
    this.panelColorTarget.value = "#e8e8e8";
    this.submitButtonTarget.click();
  }

  darkpreset(e) {
    e.preventDefault();
    this.logoColorTarget.value = "#161e2e";
    this.activeColorTarget.value = "#161e2e";
    this.backgroundColorTarget.value = "#252f3f";
    this.hoverColorTarget.value = "#374151";
    this.textColorTarget.value = "#ffffff";
    this.panelColorTarget.value = "#242e3d";
    this.submitButtonTarget.click();
  }

  change() {
    this.submitButtonTarget.click();
  }
}
