import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "qtyBreak",
    "ttlprice",
    "calcamount",
    "orderinfo",
    "calcamounttax",
    "orderqty",
    "submitbutton",
  ];

  connect() {
    this.calculate();
  }

  calculate() {
    this.orderqtyTargets.forEach((t) => {
      this.reChange(t);
    });
  }

  reChange(e) {
    let qty = e.value;
    let priceLabel = e.parentElement.parentElement.querySelector(".ttlprice");
    let unitPrice = e.parentElement.parentElement.querySelector(
      ".unitprice span.qty-break"
    );

    let qtyBreakPrices = JSON.parse(unitPrice.dataset.qtybreakprices);
    let qtyBreaks = JSON.parse(unitPrice.dataset.qtybreaks);

    // if the price is 0 then set the label to $0.00
    if (qty == "") {
      priceLabel.innerText = "$0.00";
      priceLabel.dataset.amount = 0.0;
    } else {
      // find the price break
      // if only one price break or qty >= the last qty break
      if (
        qtyBreaks.length == 1 ||
        parseInt(qty) >= qtyBreaks[qtyBreaks.length - 1]
      ) {
        let price = parseInt(qty) * qtyBreakPrices[qtyBreakPrices.length - 1];
        priceLabel.innerText = "$" + price.toFixed(2);
        priceLabel.dataset.amount = price;
      } else {
        let counter;
        for (counter = 0; counter < qtyBreaks.length; counter++) {
          if (
            qtyBreaks[counter] == parseInt(qty) ||
            qtyBreaks[counter + 1] > parseInt(qty)
          ) {
            let price = parseInt(qty) * qtyBreakPrices[counter];
            priceLabel.innerText = "$" + price.toFixed(2);
            priceLabel.dataset.amount = price;
            break;
          }
        }
      }
    }

    // iterate through all prices to grab total
    let totalPrice = 0.0;
    this.ttlpriceTargets.map((t) => {
      totalPrice = totalPrice + parseFloat(t.dataset.amount);
    });
    this.calcamountTarget.innerText = "$" + totalPrice.toFixed(2);

    if (this.hasCalcamounttaxTarget) {
      this.calcamounttaxTarget.innerText = "$" + (totalPrice / 1.1).toFixed(2);
    }

    let totalQty = 0;
    this.orderqtyTargets.map((t) => {
      if (!isNaN(parseInt(t.value))) {
        totalQty = totalQty + parseInt(t.value);
      }
    });

    if (this.hasOrderinfoTarget) {
      if (totalQty > 0) {
        this.orderinfoTarget.classList.add("tw-hidden");
        this.submitbuttonTarget.classList.remove("tw-hidden");
      } else {
        this.orderinfoTarget.classList.remove("tw-hidden");
        this.submitbuttonTarget.classList.add("tw-hidden");
      }
    } else {
      if (totalQty > 0) {
        this.submitbuttonTarget.disabled = false;
      } else {
        this.submitbuttonTarget.disabled = true;
    }
    }
  }

  qtyChange(e) {
    let qty = e.currentTarget.value;
    let priceLabel =
      e.currentTarget.parentElement.parentElement.querySelector(".ttlprice");
    let unitPrice = e.currentTarget.parentElement.parentElement.querySelector(
      ".unitprice span.qty-break"
    );

    let qtyBreakPrices = JSON.parse(unitPrice.dataset.qtybreakprices);
    let qtyBreaks = JSON.parse(unitPrice.dataset.qtybreaks);

    // if the price is 0 then set the label to $0.00
    if (qty == "") {
      priceLabel.innerText = "$0.00";
      priceLabel.dataset.amount = 0.0;
    } else {
      // find the price break
      // if only one price break or qty >= the last qty break
      if (
        qtyBreaks.length == 1 ||
        parseInt(qty) >= qtyBreaks[qtyBreaks.length - 1]
      ) {
        let price = parseInt(qty) * qtyBreakPrices[qtyBreakPrices.length - 1];
        priceLabel.innerText = "$" + price.toFixed(2);
        priceLabel.dataset.amount = price;
      } else {
        let counter;
        for (counter = 0; counter < qtyBreaks.length; counter++) {
          if (
            qtyBreaks[counter] == parseInt(qty) ||
            qtyBreaks[counter + 1] > parseInt(qty)
          ) {
            let price = parseInt(qty) * qtyBreakPrices[counter];
            priceLabel.innerText = "$" + price.toFixed(2);
            priceLabel.dataset.amount = price;
            break;
          }
        }
      }
    }

    // iterate through all prices to grab total
    let totalPrice = 0.0;
    this.ttlpriceTargets.map((t) => {
      totalPrice = totalPrice + parseFloat(t.dataset.amount);
    });
    this.calcamountTarget.innerText = "$" + totalPrice.toFixed(2);

    if (this.hasCalcamounttaxTarget) {
      this.calcamounttaxTarget.innerText = "$" + (totalPrice / 1.1).toFixed(2);
    }

    let totalQty = 0;

    this.orderqtyTargets.map((t) => {
      if (!isNaN(parseInt(t.value))) {
        totalQty = totalQty + parseInt(t.value);
      }
    });


    if (this.hasOrderinfoTarget) {
      if (totalQty > 0) {
        this.orderinfoTarget.classList.add("tw-hidden");
        this.submitbuttonTarget.classList.remove("tw-hidden");
      } else {
        this.orderinfoTarget.classList.remove("tw-hidden");
        this.submitbuttonTarget.classList.add("tw-hidden");
      }
    } else {
      if (totalQty > 0) {
        this.submitbuttonTarget.disabled = false;
      } else {
        this.submitbuttonTarget.disabled = true;
      }
    }
  }
}
