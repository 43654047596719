import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "totalPrice",
    "digitalItem",
    "digitalSelectAll",
    "asset",
    "totalPriceAll",
  ];

  printItemChanged() {
    this.highlightAssetInDesign();
    this.updateTotalPrice();
  }

  digitalItemChanged() {
    let uncheckedCount = document.querySelectorAll(
      ".diy-asset-item:not(:checked)"
    ).length;

    if (uncheckedCount > 0) {
      this.digitalSelectAllTarget.checked = false;
    }

    if (uncheckedCount === 0) {
      this.digitalSelectAllTarget.checked = true;
    }

    this.highlightAssetInDesign();
    this.updateTotalPrice();
  }

  digitalItemAllChanged(e) {
    this.digitalItemTargets.forEach(
      (cb) => (cb.checked = e.currentTarget.checked)
    );
    this.highlightAssetInDesign();
    this.updateTotalPrice();
  }

  highlightAssetInDesign() {
    // options that have been selected containing a designID
    let printDesignIds = Array.from(
      document.querySelectorAll(
        'option:checked[data-design-id]:not([value=""])'
      )
    ).map((e) => e.dataset.designId);

    // checkboxes that have been selected containing a designID
    let imageDesignIds = Array.from(
      document.querySelectorAll('input:checked[data-design-id]:not([value=""])')
    ).map((e) => e.dataset.designId);

    // join the two arrays together and uniq them
    printDesignIds = printDesignIds.concat(imageDesignIds);
    printDesignIds = Array.from(new Set(printDesignIds));

    // show / hide the image asset depending on selection
    this.assetTargets.forEach((a) => {
      let designCount = printDesignIds.filter((id) =>
        a.dataset.designs.includes(id)
      );

      let price = a.querySelector(".total-price");

      if (designCount.length === 0) {
        a.classList.remove("enabled-asset");
        a.classList.add("disabled-asset");
        price.dataset.amount = 0.0;
      } else {
        a.classList.remove("disabled-asset");
        a.classList.add("enabled-asset");
        price.dataset.amount = price.dataset.originalamount;
      }
    });
  }

  updateTotalPrice() {
    var sumAmount = this.totalPriceTargets.reduce(function (sum, x) {
      return sum + parseFloat(x.dataset.amount);
    }, 0);

    this.totalPriceAllTarget.textContent = `$${sumAmount.toFixed(2)}`;
  }
}
