import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="blank-design-placeholder"
export default class extends Controller {
  static targets = ["select", "width", "height", "container", "placeholder", "placeholderblank"];

  swap(e) {
    e.preventDefault();
    let tempHeight = this.heightTarget.value;
    let tempWidth = this.widthTarget.value;

    this.widthTarget.value = tempHeight;
    this.heightTarget.value = tempWidth;
    this.redraw();
  }

  radiochanged(e) {
    if (e.target.value == "custom") {
      this.widthTarget.required = true;
      this.heightTarget.required = true;
      console.log("custom so things are required")
    } else {
      this.widthTarget.required = false;
      this.heightTarget.required = false;
    }
  }

  redraw() {
    let inputWidth = parseFloat(this.widthTarget.value || 0);
    let inputHeight = parseFloat(this.heightTarget.value || 0);

    if (inputWidth == 0 || inputHeight == 0) {
      this.placeholderTarget.style.width = `0px`;
      this.placeholderTarget.style.height = `0px`;
      this.placeholderTarget.classList.add("hidden");
      this.placeholderblankTarget.classList.remove("hidden");
    } else {
      // Calculate the aspect ratio
      let aspectRatio = inputWidth / inputHeight;

      // Initialize the dimensions of the div
      let divWidth, divHeight;

      // Check if the width is the dominant dimension
      if (aspectRatio >= 1) {
        divWidth = 52; // Max width
        divHeight = 52 / aspectRatio; // Adjust height based on aspect ratio
      } else {
        divHeight = 52; // Max height
        divWidth = 52 * aspectRatio; // Adjust width based on aspect ratio
      }

      // Apply the calculated dimensions to the div
      this.placeholderTarget.style.width = `${divWidth}px`;
      this.placeholderTarget.style.height = `${divHeight}px`;
      this.placeholderTarget.classList.remove("hidden");
      this.placeholderblankTarget.classList.add("hidden");
    }
  }
}
