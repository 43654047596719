import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="font-upload"
export default class extends Controller {
  static targets = [ "checkbox", "fontselector", "fontselectorlabel", "orlabel", "checkboxinput", "previewname", "teamname" ]

  selected(e) {
    this.previewnameTarget.innerText = this.fileNameWithoutExtension(e.target.files[0].name);
    this.checkboxTarget.classList.remove("hidden")
    this.checkboxinputTarget.setAttribute('required', 'required');
    this.fontselectorTarget.classList.add("hidden")
    this.fontselectorlabelTarget.classList.add("hidden")
    this.orlabelTarget.classList.add("hidden")
  }

  fileNameWithoutExtension(fileName) {
    return fileName.replace(/\.[^/.]+$/, '');
  }
}
