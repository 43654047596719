import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["calcamount", "subtotal"];

  connect() {
    this.calculate();
  }

  calculate() {
    let subtotalAmount = 0.0;
    this.subtotalTargets.forEach((t) => {
      if (t.dataset.amount) {
        subtotalAmount += parseFloat(parseFloat(t.dataset.amount).toFixed(2));
      }
    });

    this.calcamountTarget.innerHTML = `$${subtotalAmount.toFixed(2)}`;
  }
}
