import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spin-on-click"
export default class extends Controller {
  static targets = ["icon"]

  click() {
    this.iconTarget.classList.add("animate-spin")
  }
}
