import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["design", "image", "sizeLabel", "newLink", "adminLink"];
  static values = {
    thumbnail: String,
    newDesignUrl: String,
    adminpath: String,
    designTitle: String,
  };
  static classes = [
    "selectedText",
    "selectedBackground",
    "selectedBorder",
    "unselectedText",
    "unselectedBackground",
    "unselectedBorder",
  ];

  selected(event) {
    event.preventDefault();

    const thumbnailUrl = event.currentTarget.dataset.thumbnailUrl;
    this.imageTarget.src = thumbnailUrl;

    if (this.hasNewLinkTarget) {
      this.newLinkTarget.href = `${this.newDesignUrlValue}?id=${event.currentTarget.dataset.designId}`;
    }

    if (this.hasAdminLinkTarget) {
      this.adminLinkTarget.href = `${this.adminpathValue}?id=${event.currentTarget.dataset.designId}&thumbnail=${thumbnailUrl}&title=${this.designTitleValue}`;
    }

    this.designTargets.forEach((elem) => {
      elem.dataset.isSelected = false;
      elem.classList.remove(this.selectedTextClass);
      elem.classList.remove(this.selectedBackgroundClass);
      elem.classList.remove(this.selectedBorderClass);
      elem.classList.add(this.unselectedTextClass);
      elem.classList.add(this.unselectedBackgroundClass);
      elem.classList.add(this.unselectedBorderClass);
    });

    const sizeLabel = event.currentTarget.dataset.sizeLabel;
    this.sizeLabelTarget.innerText = sizeLabel;
    event.currentTarget.dataset.isSelected = true;
    event.currentTarget.classList.remove(this.unselectedTextClass);
    event.currentTarget.classList.remove(this.unselectedBackgroundClass);
    event.currentTarget.classList.remove(this.unselectedBorderClass);
    event.currentTarget.classList.add(this.selectedTextClass);
    event.currentTarget.classList.add(this.selectedBackgroundClass);
    event.currentTarget.classList.add(this.selectedBorderClass);
  }

  mouseenter() {
    this.designTargets.forEach((elem) => {
      if (elem.dataset.isSelected == "false") {
        elem.classList.add(this.selectedTextClass);
        elem.classList.add(this.selectedBackgroundClass);
        elem.classList.add(this.selectedBorderClass);
        elem.classList.remove(this.unselectedTextClass);
        elem.classList.remove(this.unselectedBackgroundClass);
        elem.classList.remove(this.unselectedBorderClass);
      }
    });
  }

  mouseleave() {
    this.designTargets.forEach((elem) => {
      if (elem.dataset.isSelected == "false") {
        elem.classList.remove(this.selectedTextClass);
        elem.classList.remove(this.selectedBackgroundClass);
        elem.classList.remove(this.selectedBorderClass);
        elem.classList.add(this.unselectedTextClass);
        elem.classList.add(this.unselectedBackgroundClass);
        elem.classList.add(this.unselectedBorderClass);
      }
    });
  }
}
