import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import { parseGIF, decompressFrames } from 'gifuct-js';

// Connects to data-controller="easil-image-uploader"
export default class extends Controller {
  static outlets = ["easil-media-upload-presigner"]

  static values = {
    id: String,
    url: String,
    persisted: Boolean,
    folder: String
  }

  connect() {
    if (this.persistedValue == false) {
      const file = this.easilMediaUploadPresignerOutlet.getFile(this.idValue)
      this.checkDurationAndUpload(file);
    }
  }

  async checkDurationAndUpload(file) {
    this.uploadToAmazon({ file: file });
  }

  uploadToAmazon(fileInfo) {
    const params = {
      method: 'PUT',
      body: fileInfo.file
    }

    fetch(fileInfo.file.url, params)
      .then(response => {
        if (response.status == 200) {
          this.createInEasil(fileInfo)
        }
      })
  }

  async createInEasil(fileInfo) {
    const resul = await post(this.urlValue, { body: { id: fileInfo.file.id, filename: fileInfo.file.name, folder_id: this.folderValue }, responseKind: "turbo-stream" })
  }
}
