import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-template-finder"
export default class extends Controller {
  static targets = ["url", "submit", "urlfield"]

  changed(e) {
    if (event.key === 'Enter') {
      this.submitform(e.target.value)
    }
  }

  pasted(e) {
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');

    this.submitform(pasteData)
  }

  submitform(term) {
    this.urlfieldTarget.value = term
    this.submitTarget.click()
  }
}
