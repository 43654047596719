import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clear-frame"
export default class extends Controller {
  static values = { name: String }
  clear() {
    const frame = document.getElementById(this.nameValue)
    if (frame) {
      frame.innerHTML = ""
      frame.src = ""
    }
  }
}
