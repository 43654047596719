import { Controller } from "@hotwired/stimulus"
import Masonry from "masonry-layout"

// Connects to data-controller="masonry-grid"
export default class extends Controller {
  connect() {
    this.masonry = new Masonry(this.element, {
      itemSelector: '.masonry-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      gutter: 16,
      isAnimated: false,
      transitionDuration: '0.1s'
    })

    this.observer = new MutationObserver((mutations) => {
      let needsLayout = false;

      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          // Handle added nodes
          const newMasonryItems = Array.from(mutation.addedNodes)
            .filter(node => node.nodeType === Node.ELEMENT_NODE && node.classList.contains('masonry-item'));

          if (newMasonryItems.length > 0) {
            const firstNewNode = newMasonryItems[0];
            const parentNode = mutation.target;
            const isPrepended = parentNode.firstElementChild === firstNewNode;

            if (isPrepended) {
              this.masonry.prepended(newMasonryItems);
            } else {
              this.masonry.appended(newMasonryItems);
            }
          } else {
            needsLayout = true;
          }

          // Handle removed nodes
          const removedMasonryItems = Array.from(mutation.removedNodes)
            .filter(node => node.nodeType === Node.ELEMENT_NODE && node.classList.contains('masonry-item'));

          if (removedMasonryItems.length > 0) {
            this.masonry.remove(removedMasonryItems);
            needsLayout = true;
          }
        }
      })

      if (needsLayout) {
        this.masonry.layout();
      }
    });
    this.observer.observe(this.element, { childList: true, subtree: true, characterData: true });
  }

  disconnect() {
    if (this.masonry) {
      this.masonry.destroy();
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

