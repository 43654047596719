import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["price"];

  priceSelected(e) {
    // formatter for currency display
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    this.priceTarget.textContent = `${e.currentTarget.selectedOptions[0].dataset.price}`;
    this.priceTarget.dataset.amount =
      e.currentTarget.selectedOptions[0].dataset.rawPrice;

    // how many items have been selected in the current item
    let itemsSelectedCount = e.currentTarget
      .closest(".print-item")
      .querySelectorAll('option:checked:not([value=""])').length;

    // iterate through any paid assets
    let item = e.currentTarget.closest(".print-item");
    Array.from(item.querySelectorAll(".paid-asset")).map((ele) => {
      if (itemsSelectedCount > 0) {
        if (document.getElementById("order-item-errors")) {
          document.getElementById("order-item-errors").classList.add("hidden");
        }
        let rawPrice = ele.querySelector("span.raw-price").dataset.rawPrice;
        ele.querySelector("div.item-price").dataset.amount = rawPrice;
        ele.querySelector("div.item-price").innerHTML =
          formatter.format(rawPrice);
      } else {
        ele.querySelector("div.item-price").dataset.amount = 0.0;
        ele.querySelector("div.item-price").innerHTML = `$0.00`;
      }
    });

    // iterate through all "item-price" and get a total
    let totalAmount = Array.from(
      document.getElementsByClassName("item-price")
    ).reduce((acc, t) => acc + parseFloat(t.dataset.amount), 0);

    // assign it to the total price
    if (document.getElementById("total-price")) {
      document.getElementById("total-price").innerText =
        formatter.format(totalAmount);
    }
  }

  digitalSelected(e) {
    // formatter for currency display
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    // how many items have been selected in the current item
    let itemsSelected = e.currentTarget.checked;

    // iterate through any paid assets
    let item = e.currentTarget.closest(".print-item");
    Array.from(item.querySelectorAll(".paid-asset")).map((ele) => {
      if (itemsSelected) {
        if (document.getElementById("order-item-errors")) {
          document.getElementById("order-item-errors").classList.add("hidden");
        }
        let rawPrice = ele.querySelector("span.raw-price").dataset.rawPrice;
        ele.querySelector("div.item-price").dataset.amount = rawPrice;
        ele.querySelector("div.item-price").innerHTML =
          formatter.format(rawPrice);
      } else {
        ele.querySelector("div.item-price").dataset.amount = 0.0;
        ele.querySelector("div.item-price").innerHTML = `$0.00`;
      }
    });

    // iterate through all "item-price" and get a total
    let totalAmount = Array.from(
      document.getElementsByClassName("item-price")
    ).reduce((acc, t) => acc + parseFloat(t.dataset.amount), 0);

    // assign it to the total price
    if (document.getElementById("total-price")) {
      document.getElementById("total-price").innerText =
        formatter.format(totalAmount);
    }
  }
}
