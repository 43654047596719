import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="formsubmitbg"
export default class extends Controller {
  static targets = [ "input", "submit" ]

  toggleButtonColor() {
    if (this.inputTarget.value.trim() === '') {
      // If the field is empty, set the button to gray
      this.submitTarget.classList.remove('bg-bblue-300');
      this.submitTarget.classList.add('bg-ggrey-300');
      this.submitTarget.disabled = true;
    } else {
      // If the field is not empty, set the button to blue
      this.submitTarget.classList.remove('bg-ggrey-300');
      this.submitTarget.classList.add('bg-bblue-300');
      this.submitTarget.disabled = false;
    }
  }
}
