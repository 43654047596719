import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="font-dropdown"
export default class extends Controller {
  static targets = [ "dropdown", "list", "selected", "previewdescription", "previewname", "container", "fontid", "stylesheeturl", "previewurl", "fontfamilyname", "fontname" ]

  connect() {
    useClickOutside(this, {
      element: this.containerTarget
    });
  }

  clickOutside() {
    this.listTarget.classList.add("hidden")
  }

  toggle() {
    this.listTarget.classList.toggle("hidden")
  }

  select(e) {
    console.log(e.target.dataset)
    this.listTarget.classList.add("hidden")
    this.selectedTarget.innerText = e.target.dataset.fontName
    this.previewnameTarget.innerText = e.target.dataset.fontName
    this.fontidTarget.value = e.target.dataset.fontId
    this.previewdescriptionTarget.style.fontFamily = e.target.dataset.fontFamily
    this.fontfamilynameTarget.value = e.target.dataset.fontFamily
    this.previewdescriptionTarget.innerText = e.target.dataset.fontName
    this.fontnameTarget.value = e.target.dataset.fontName
  }
}
