import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-reset"
export default class extends Controller {
  static values = {
    path: String
  }

  click(e) {
    e.preventDefault();
    window.location = this.pathValue;
  }
}
