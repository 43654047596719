import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog-closer"
export default class extends Controller {
  close(e) {
    e.preventDefault();
    let dialog = document.querySelector("dialog")
    if (dialog) {
      dialog.close()
    }
  }
}
