import { StreamActions } from "@hotwired/turbo"

StreamActions.openBrandKitColor = function() {
  const colorId = this.getAttribute("colorId")
  const parentDiv = document.getElementById(`team_color_${colorId}`)

  if (parentDiv) {
    const colorInputs = parentDiv.querySelectorAll('details summary');
    const lastColorInput = colorInputs[colorInputs.length - 1];

    if (lastColorInput) {
      lastColorInput.click();
    }
  }
}
