import Toastify from "toastify-js"

import { StreamActions } from "@hotwired/turbo"

StreamActions.toast = function() {
  const message = this.getAttribute("message")
  const position = this.getAttribute("position")
  const destination = this.getAttribute("destination") || ""

  Toastify({
    text: message,
    duration: 4000,
    destination: destination,
    close: true,
    gravity: "top",
    position: position,
    stopOnFocus: true,
    style: {
      background: "#2c2e2f",
      padding: "24px",
      borderRadius: "8px",
    },
    escapeMarkup: false,
    offset: {
      y: 60,
      x: 10
    }
  }).showToast()
}

