import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expanding-textfield"
export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.resize()
    this.moveCaretToEnd()
  }

  resize() {
    this.fieldTarget.style.width = "auto"
    this.fieldTarget.style.width = `${this.fieldTarget.scrollWidth}px`
  }

  update() {
    this.resize()
    this.moveCaretToEnd()
  }

  moveCaretToEnd() {
    const val = this.fieldTarget.value
    this.fieldTarget.value = ''
    this.fieldTarget.value = val
  }
}
