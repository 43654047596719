import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-add-design"
export default class extends Controller {
  static outlets = ["masonry"]

  static values = {
    design: String
  }

  connect() {
    this.masonryOutlet.addDesign(this.designValue)
  }
}
