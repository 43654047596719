import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.toggleClass = this.element.dataset.hideClass || "tw-hidden";
    useClickOutside(this, {
      element: this.element
    });
  }

  clickOutside() {
    this.menuTarget.classList.add(this.toggleClass);
  }

  toggle() {
    this.menuTarget.classList.toggle(this.toggleClass);
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass);
    }
  }
}
