import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import { parseGIF, decompressFrames } from 'gifuct-js';

// Connects to data-controller="brand-kit-logos-downloader"
export default class extends Controller {
  static outlets = ["brand-kit-logos"]

  static values = {
    id: String,
    url: String,
    persisted: Boolean,
    folder: String
  }

  connect() {
    if (this.persistedValue == false) {
      const file = this.brandKitLogosOutlet.getFile(this.idValue)
      this.checkDurationAndUpload(file);
    }
  }

  async checkDurationAndUpload(file) {
    try {
      let fileInfo = await this.longerThanTenMinutes(file);

      if ((file.type === 'video/mp4' || file.type == "image/gif") && fileInfo.isLongerThanTenMinutes) {
        this.dispatch('longmedia', { detail: { message: 'Media file is longer than 10 minutes' }, duration: fileInfo.duration  });
      } else {
        this.uploadToAmazon({file: file, duration: fileInfo.duration});
      }

    } catch (error) {
      console.log("An error occurred while checking the file duration:", error)
    }
  }

  longerThanTenMinutes(file) {
    return new Promise(resolve => {
      if (file.type === 'video/mp4') {
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.onloadedmetadata = () => {
          window.URL.revokeObjectURL(videoElement.src);
          const duration = videoElement.duration;
          resolve({isLongerThanTenMinutes: duration > 600, duration: duration}); // 600 seconds = 10 minutes
        };
        videoElement.onerror = () => {
          resolve(false); // If there's an error loading the video, resolve to false.
        };
        videoElement.src = URL.createObjectURL(file);
      } else if (file.type === 'image/gif') {

        this.getGifFrames(file).then(frames => {
          // frames is an array of frame data
          // You can calculate the duration or do whatever you need with the frames here.
            let duration = 0;
            for (let i = 0; i < frames.length; i++) {
              duration += frames[i].delay;
            }
            // // Convert to seconds and check if it's longer than 10 minutes.
            let durationInSeconds = duration / 1000;
            resolve({isLongerThanTenMinutes: durationInSeconds > 600, duration: durationInSeconds});

        }).catch(error => {
          console.error('Error processing GIF:', error);
        });
      } else {
        resolve(false); // If it's neither an MP4 nor a GIF, resolve to false.
      }
    });
  }

  getGifFrames(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const buffer = event.target.result;
          const gif = parseGIF(buffer);
          const frames = decompressFrames(gif, true);
          resolve(frames);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => {
        reject(new Error('Error reading the GIF file.'));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  uploadToAmazon(fileInfo) {
    const params = {
      method: 'PUT',
      body: fileInfo.file
    }

    fetch(fileInfo.file.url, params)
      .then(response => {
        if (response.status == 200) {
          this.createInEasil(fileInfo)
        }
      })
  }

  async createInEasil(fileInfo) {
    if (fileInfo.duration) {
      const resul = await post(this.urlValue, { body: {id: fileInfo.file.id, filename: fileInfo.file.name, duration: fileInfo.duration, folder_id: this.folderValue }, responseKind: "turbo-stream" })
    } else {
      const resul = await post(this.urlValue, { body: {id: fileInfo.file.id, filename: fileInfo.file.name, folder_id: this.folderValue }, responseKind: "turbo-stream" })
    }
  }
}
