import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-share-filter"
export default class extends Controller {
  static targets = ["user", "infopanel", "sharingwithlabel", "donebutton", "addbutton", "search"]

  connect() {
    this.showAllocationsOnly();
  }

  find() {
    let searchString = this.searchTarget.value.toLowerCase()

    this.userTargets.forEach((user) => {
      let userName = user.querySelector('[data-diy-share-filter-target="username"]').innerText.toLowerCase()
      let userEmail = user.querySelector('[data-diy-share-filter-target="useremail"]').innerText.toLowerCase()

      if (userName.includes(searchString) || userEmail.includes(searchString)) {
        user.style.display = "flex"
      } else {
        user.style.display = "none"
      }
    })
  }

  showAll() {
    this.userTargets.forEach(user => {
      user.style.display = "flex"
    });
  }

  showUnallocated() {
    this.userTargets.forEach(user => {
      if(user.dataset.allocationId) {
        user.style.display = "none"
      } else {
        user.style.display = "flex"
      }
    });
    this.searchTarget.value = "";
    this.infopanelTarget.classList.add('hidden');
    this.searchTarget.classList.remove('hidden');
    this.searchTarget.focus();
    this.sharingwithlabelTarget.classList.add('hidden');
    this.addbuttonTarget.classList.add('hidden');
    this.donebuttonTarget.classList.remove('hidden');
  }

  showAllocationsOnly() {
    let hasAllocation = false;

    this.userTargets.forEach(user => {
      if(user.dataset.allocationId) {
        user.style.display = "flex"
        hasAllocation = true;
      } else {
        user.style.display = "none"
      }
    });
    this.searchTarget.value = "";
    this.searchTarget.classList.add('hidden');
    this.infopanelTarget.classList.remove('hidden');
    this.sharingwithlabelTarget.classList.remove('hidden');
    this.addbuttonTarget.classList.remove('hidden');
    this.donebuttonTarget.classList.add('hidden');

    if (hasAllocation) {
      this.sharingwithlabelTarget.classList.remove('invisible');
    } else {
      this.sharingwithlabelTarget.classList.add('invisible');
    }
  }
}
