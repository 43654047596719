import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ["name"]

  open() {
    this.element.showModal();
  }

  cancel(e) {
    e.preventDefault();
    let closestForm = e.currentTarget.closest("form")
    if (closestForm) {
      closestForm.reset()
    }

    let folderNameInput = document.getElementById("easil_folder_name")
    if (folderNameInput) {
      folderNameInput.value = ""
    }

    let folderError = document.getElementById("new-folder-error")
    if (folderError) {
      folderError.remove()
    }

    this.element.close();
  }
}
