import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["total", "subtotal"];

  change() {
    var sumAmount = this.subtotalTargets.reduce(function (sum, x) {
      return sum + parseFloat(x.dataset.amount);
    }, 0);

    this.totalTarget.textContent = `$${sumAmount.toFixed(2)}`;
  }
}
