import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slideshow"
export default class extends Controller {
  static targets = ["slide"]
  static outlets = ["slideshow-dots"]

  connect() {
    this.currentIndex = 0
    this.updateDots(this.currentIndex)  // Add this line
    this.observeSlides()
    this.autoAdvanceTimeout = null
  }

  observeSlides() {
    const options = {
      root: this.element,
      rootMargin: '0px -49% 0px -49%',
      threshold: 0
    }

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.currentIndex = this.slideTargets.indexOf(entry.target)
          this.updateDots(this.currentIndex)
        }
      })
    }, options)

    this.slideTargets.forEach((slide) => {
      this.observer.observe(slide)
    })
  }

  updateDots(index) {
    if (this.hasSlideshowDotsOutlet) {
      this.slideshowDotsOutlet.highlightDot(index)
    } else {
    }
  }

  handleMouseEnter() {
    this.scheduleNextAdvance()
  }

  handleMouseLeave() {
    this.cancelAutoAdvance()
  }

  scheduleNextAdvance() {
    this.cancelAutoAdvance()
    this.autoAdvanceTimeout = setTimeout(() => {
      this.advanceSlide()
      this.scheduleNextAdvance()
    }, 2000)
  }

  cancelAutoAdvance() {
    if (this.autoAdvanceTimeout) {
      clearTimeout(this.autoAdvanceTimeout)
      this.autoAdvanceTimeout = null
    }
  }

  advanceSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.slideTargets.length
    this.scrollToSlide(this.currentIndex)
  }

  scrollToSlide(index) {
    const slide = this.slideTargets[index]
    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    this.currentIndex = index
    this.updateDots(index)
  }

  disconnect() {
    this.observer.disconnect()
    this.cancelAutoAdvance()
  }
}
