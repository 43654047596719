import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="font-search"
export default class extends Controller {
  static targets = ["search", "font"]

  connect() {
    this.updateFontList()
  }

  updateFontList() {
    const searchTerm = this.searchTarget.value.toLowerCase();

    this.fontTargets.forEach((fontDiv) => {
      const fontName = fontDiv.textContent.toLowerCase();
      if (searchTerm === "" || fontName.includes(searchTerm)) {
        fontDiv.style.display = "block";
      } else {
        fontDiv.style.display = "none";
      }
    });
  }
}
