import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="design-dialog"
export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    this.element.showModal();
  }

  close(e) {
    e.preventDefault();
    this.element.close();
    this.element.remove()
  }

  enter(e) {
    if (e.keyCode == 13 && this.hasFormTarget) {
      e.preventDefault();
      this.formTarget.requestSubmit();
    }
  }

  clickoutside(event) {
    const rect = this.element.getBoundingClientRect();
    if (event.clientY < rect.top || event.clientY > rect.bottom ||
        event.clientX < rect.left || event.clientX > rect.right) {
          this.element.close();
          this.element.remove();
       }
  }
}
