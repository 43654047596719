import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-request-action"
export default class extends Controller {
  static targets = ["option", "declineButton", "approveButton", "textarea", "toggle", "approvelabel", "declinelabel"];

  optionChanged(e) {
    if (e.currentTarget.innerText == "Decline") {
      this.declineButtonTarget.classList.remove("tw-hidden")
      this.approveButtonTarget.classList.add("tw-hidden")
      this.textareaTarget.placeholder = "I'm declining this request because..."
      this.toggleTarget.classList.add("left-1/2")
      this.approvelabelTarget.classList.remove("font-medium")
      this.approvelabelTarget.classList.add("font-normal")
      this.declinelabelTarget.classList.add("font-medium")
      this.declinelabelTarget.classList.remove("font-normal")
    } else {
      this.declineButtonTarget.classList.add("tw-hidden")
      this.approveButtonTarget.classList.remove("tw-hidden")
      this.textareaTarget.placeholder = "I'm approving this request because..."
      this.toggleTarget.classList.remove("left-1/2")
      this.approvelabelTarget.classList.add("font-medium")
      this.approvelabelTarget.classList.remove("font-normal")
      this.declinelabelTarget.classList.remove("font-medium")
      this.declinelabelTarget.classList.add("font-normal")
    }
  }
}
