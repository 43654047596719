import { Controller } from "@hotwired/stimulus";

// Shows/hides the alert box on order form that says "You haven't selected any web images with this order"
export default class extends Controller {
  static targets = ["alert"];

  change(e) {
    let selected = e.currentTarget.selectedOptions[0].value != "";

    if (selected) {
      this.alertTarget.classList.add("tw-hidden");
    } else {
      this.alertTarget.classList.remove("tw-hidden");
    }
  }
}
