import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["total", "select"];

  change(e) {
    let item = e.target.selectedOptions[0];
    if (item.value == "") {
      this.totalTarget.textContent = "$0.00";
      this.totalTarget.dataset.amount = 0;
    } else {
      let qty = item.dataset.qty;
      let price = item.dataset.price;
      let totalPrice = qty * price;
      this.totalTarget.textContent = `$${totalPrice.toFixed(2)}`;
      this.totalTarget.dataset.amount = totalPrice;
    }
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("poster-row-changed", true, true, null);
    this.element.dispatchEvent(event);
  }
}
