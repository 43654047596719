import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["width", "height", "length", "unit", "svg"]

  connect() {
    if (this.hasAllTargets) {
      this.updateDiagram()
    }
  }

  updateDiagram() {
    if (!this.hasAllTargets) return

    const width = parseFloat(this.widthTarget.value)
    const height = parseFloat(this.heightTarget.value)
    const length = parseFloat(this.lengthTarget.value)
    const unit = this.unitTarget.value

    // Scale factors for SVG
    const scale = 15
    const depthScale = 0.3 // Reduced for a more subtle 3D effect
    const svgWidth = (width + length * depthScale) * scale + 100
    const svgHeight = (height + length * depthScale) * scale + 100

    // Calculate dimensions
    const scaledWidth = width * scale
    const scaledHeight = height * scale
    const scaledLength = length * scale * depthScale

    // Calculate the angle of the depth line
    const depthAngle = Math.atan2(scaledLength, scaledLength) * (180 / Math.PI)

    // SVG paths for the package
    const frontFace = `M 50 ${svgHeight - 50} l ${scaledWidth} 0 l 0 ${-scaledHeight} l ${-scaledWidth} 0 Z`
    const topFace = `M 50 ${svgHeight - 50 - scaledHeight} l ${scaledWidth} 0 l ${scaledLength} ${-scaledLength} l ${-scaledWidth} 0 Z`
    const sideFace = `M ${50 + scaledWidth} ${svgHeight - 50} l 0 ${-scaledHeight} l ${scaledLength} ${-scaledLength} l 0 ${scaledHeight} Z`

    // Update SVG
    this.svgTarget.setAttribute("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
    this.svgTarget.innerHTML = `
      <path d="${frontFace}" fill="#f0f0f0" stroke="black" />
      <path d="${topFace}" fill="#e0e0e0" stroke="black" />
      <path d="${sideFace}" fill="#d0d0d0" stroke="black" />
      <text x="${scaledWidth / 2 + 50}" y="${svgHeight - 25}" text-anchor="middle">${width} ${unit}</text>
      <text x="${scaledWidth + 60}" y="${svgHeight - scaledHeight / 2 - 25}" text-anchor="middle" transform="rotate(90 ${scaledWidth + 60} ${svgHeight - scaledHeight / 2 - 25})">${height} ${unit}</text>
      <text x="${scaledWidth + scaledLength / 2 + 50}" y="${svgHeight - scaledHeight - scaledLength / 2 - 10}" text-anchor="middle" transform="rotate(${-depthAngle} ${scaledWidth + scaledLength / 2 + 50} ${svgHeight - scaledHeight - scaledLength / 2 - 10})">${length} ${unit}</text>
    `
  }

  dimensionChanged() {
    this.updateDiagram()
  }

  get hasAllTargets() {
    return this.hasWidthTarget && this.hasHeightTarget && this.hasLengthTarget && this.hasUnitTarget && this.hasSvgTarget
  }
}
