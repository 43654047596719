import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["totalPrice"];

  changed(e) {
    let item = e.target.selectedOptions[0];

    if (item.value == "") {
      this.totalPriceTarget.textContent = "$0.00";
      this.totalPriceTarget.dataset.amount = 0;
    } else {
      let qty = item.dataset.qty;
      let price = item.dataset.price;
      let single = item.dataset.qtySingle;

      let totalPrice;
      if (single) {
        totalPrice = price * 1;
      } else {
        totalPrice = price * qty;
      }
      this.totalPriceTarget.textContent = `$${totalPrice.toFixed(2)}`;
      this.totalPriceTarget.dataset.amount = totalPrice;
    }

    let nodesArray = [].slice.call(document.querySelectorAll(".total-price"));
    var sumAmount = nodesArray.reduce(function (sum, x) {
      return sum + parseFloat(x.dataset.amount);
    }, 0);

    document.getElementsByClassName(
      "total-price-all"
    )[0].textContent = `$${sumAmount.toFixed(2)}`;
  }
}
