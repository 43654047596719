import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

// Connects to data-controller="diy-move-design-to-folder"
export default class extends Controller {
  static values = {
    url: String,
    folder: String,
    collection: String,
    user: String,
    team: String,
    design: String
  }

  async move(e) {
    e.preventDefault();
    const response = await put(this.urlValue, { body: JSON.stringify({ designId: this.designValue, collectionId: this.collectionValue, folderId: this.folderValue, userId: this.userValue, teamId: this.teamValue }), responseKind: "turbo-stream" })
  }

  async remove(e) {
    e.preventDefault();
    const response = await put(this.urlValue, { body: JSON.stringify({ designId: this.designValue, collectionId: this.collectionValue, folderId: "root", userId: this.userValue, teamId: this.teamValue }), responseKind: "turbo-stream" })
  }
}
