import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="brand-kit-logos"
export default class extends Controller {
  static targets = ["files"]
  static values = { url: String }

  connect() {
    this.fileList = []
  }

  getFile(id) {
    return this.fileList.find(file => file.id === id)
  }

  selected() {
    Array.from(this.filesTarget.files).forEach(async(file) => {
      const result = await post(this.urlValue, { body: {filename: file.name, content_type: file.type}, contentType: "application/json", responseKind: "json" })
      const stuff = await result.json
      const resul = await post(this.urlValue, { body: {id: stuff.id, filename: stuff.filename, file_size: file.size }, responseKind: "turbo-stream" })
      file.id = stuff.id
      file.url = stuff.url
      this.fileList.push(file)
    });

    this.filesTarget.value = null;
  }
}
