import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-hover-play"
export default class extends Controller {
  connect() {
    console.log("hi")
  }

  startPlay() {
    this.element.play()
  }

  stopPlay() {
    this.element.pause()
  }
}
