import { Controller } from "@hotwired/stimulus";

// Shows/hides the alert box on order form that says "Please complete the 'Promo Dates' section."
export default class extends Controller {
  static targets = ["fromdate", "todate", "alert"];

  change(e) {
    let filledIn =
      this.todateTarget.value.length > 0 &&
      this.fromdateTarget.value.length > 0;

    if (filledIn) {
      this.alertTarget.classList.add("tw-hidden");
    } else {
      this.alertTarget.classList.remove("tw-hidden");
    }
  }
}
