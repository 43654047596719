import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-switch"
export default class extends Controller {
  static targets = ["switch", "switchbg", "iconoff", "iconon"]
  static values = { submit: Boolean }

  toggle() {
    this.switchbgTarget.classList.toggle("bg-ggrey-400")
    this.switchbgTarget.classList.toggle("bg-bblue-300")
    this.switchTarget.classList.toggle("translate-x-0")
    this.switchTarget.classList.toggle("translate-x-6")
    this.iconoffTarget.classList.toggle("opacity-0")
    this.iconoffTarget.classList.toggle("opacity-100")
    this.iconoffTarget.classList.toggle("duration-100")
    this.iconoffTarget.classList.toggle("duration-200")
    this.iconoffTarget.classList.toggle("ease-out")
    this.iconoffTarget.classList.toggle("ease-in")
    this.icononTarget.classList.toggle("opacity-0")
    this.icononTarget.classList.toggle("opacity-100")
    this.icononTarget.classList.toggle("duration-100")
    this.icononTarget.classList.toggle("duration-200")
    this.icononTarget.classList.toggle("ease-out")
    this.icononTarget.classList.toggle("ease-in")

    if (this.submitValue == true) {
      this.element.closest("form").requestSubmit()
    }
  }
}
