import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="disclosure"
export default class extends Controller {
  static targets = ["details", "content"];
  static observedAttributes = ["open"];

  connect() {
    this.observeOpenAttribute();
    useClickOutside(this, {
      element: this.element
    });
  }

  clickOutside() {
    this.detailsTarget.removeAttribute("open");
  }

  observeOpenAttribute() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
          this.toggleOverlay();
        }
      });
    });

    this.observer.observe(this.detailsTarget, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  toggleOverlay() {
    const isOpen = this.detailsTarget.hasAttribute("open");
    let overlay = document.getElementById('download-overlay')
    if (overlay) {
      overlay.classList.toggle('hidden', !isOpen);
    }
  }

  toggle() {
    if (this.detailsTarget.hasAttribute("open")) {
      const currentSrc = this.contentTarget.getAttribute("src");
      this.contentTarget.setAttribute("src", "");
      this.contentTarget.setAttribute("src", currentSrc);
    } else {
      this.contentTarget.innerHTML = "";
    }
  }
}
