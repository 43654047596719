import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="diy-upload-selected-action"
export default class extends Controller {
  static outlets = ["slave", "video-dialog", "move-dialog"];
  static targets = ["count"];

  blah() {
    let checked = this.slaveOutlet.itemTargets.filter(
      (checkbox) => checkbox.checked
    );
    let count = checked.length;

    if (count == 1) {
      this.countTarget.innerHTML = "1 item";
    } else {
      this.countTarget.innerHTML = `${checked.length} items`;
    }

    Array.from(document.getElementsByName("asset_id[]")).map((c) => {
      c.remove();
    });

    if (count > 0) {
      this.element.classList.remove("hidden");
      let _this = this;
      checked.map((c) => {
        let fn = document.createElement("input");
        fn.setAttribute("type", "hidden");
        fn.setAttribute("name", "asset_id[]");
        fn.setAttribute("value", c.value);
        _this.videoDialogOutletElement.appendChild(fn);
        _this.videoDialogOutlet.countValue = count;

        let fnn = document.createElement("input");
        fnn.setAttribute("type", "hidden");
        fnn.setAttribute("name", "asset_id[]");
        fnn.setAttribute("value", c.value);

        _this.moveDialogOutletElement.appendChild(fnn);
        _this.moveDialogOutlet.countValue = count;
      });
    } else {
      this.element.classList.add("hidden");
    }
  }
}
