import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-brand-managers"
export default class extends Controller {
  static targets = [ "checkbox", "nextbutton" ]

  connect() {
    this.updateCount();
  }

  selectall(e) {
    e.preventDefault();
    this.checkboxTargets.forEach(checkbox => checkbox.checked = true);
    this.updateCount();
  }

  updateCount() {
    const checkedCount = this.checkboxTargets.filter(checkbox => checkbox.checked).length;

    const nextButton = this.nextbuttonTarget;
    if (checkedCount > 0) {
      nextButton.classList.add("butn--primary");
      nextButton.classList.remove("butn");
    } else {
      nextButton.classList.remove("butn--primary");
      nextButton.classList.add("butn");
    }

    nextButton.disabled = checkedCount === 0;
  }
}
