import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["priceTable"];

  selectprice(e) {
    if (e.currentTarget.checked == false) {
      this.priceTableTarget.classList.add("tw-hidden");
    } else {
      this.priceTableTarget.classList.remove("tw-hidden");

      // fetch the data and show a partial if data is found
      // or not found
    }
  }
}
