import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slideshow-dots"
export default class extends Controller {
  static targets = ["dot"]
  static outlets = ["slideshow"]

  connect() {
    if (this.hasSlideshowOutlet) {
      this.highlightDot(this.slideshowOutlet.currentIndex)
    } else {
      this.highlightDot(0) // Fallback to highlighting the first dot
    }
  }

  highlightDot(index) {
    this.dotTargets.forEach((dot, i) => {
      if (i === index) {
        dot.classList.add('bg-blue-500')
        dot.classList.remove('bg-gray-400')
      } else {
        dot.classList.add('bg-gray-400')
        dot.classList.remove('bg-blue-500')
      }
    })
  }

  dotClicked(event) {
    const clickedDot = event.currentTarget
    const index = this.dotTargets.indexOf(clickedDot)
    this.highlightDot(index)
    if (this.hasSlideshowOutlet) {
      this.slideshowOutlet.scrollToSlide(index)
    } else {
    }
  }
}
