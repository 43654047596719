import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="update-brand-kit-color"
export default class extends Controller {
  static targets = ["label", "picker", "input", "color"]

  save(event) {
    const formElement = event.target.closest("form");

    if (formElement) {
      formElement.requestSubmit();
    }
  }

  colorpicked(event) {
    this.inputTarget.color = event.detail;
    this.pickerTarget.color = event.detail;
    this.labelTarget.style.backgroundColor = event.detail;
    this.colorTarget.value = event.detail;
    if (event.detail === "#ffffff") {
      this.labelTarget.style.borderColor = "#e2e5e8";
    } else {
      this.labelTarget.style.borderColor = event.detail;
    }
  }

  inputchanged(event) {
    if (event.detail.value.length == 7) {
      this.pickerTarget.color = event.detail.value;
      this.labelTarget.style.backgroundColor = event.detail.value;
      this.colorTarget.value = event.detail.value;
      if (event.target.color === "#ffffff") {
        this.labelTarget.style.borderColor = "#e2e5e8";
      } else {
        this.labelTarget.style.borderColor = event.detail.value;
      }
    }
  }

  changepreview(event) {
    this.labelTarget.style.backgroundColor = event.target.color;
    this.colorTarget.value = event.detail.value;
    if (event.target.color === "#ffffff") {
      this.labelTarget.style.borderColor = "#e2e5e8";
    } else {
      this.labelTarget.style.borderColor = event.target.color;
    }
    this.inputTarget.color = event.detail.value;
  }

  delete(event) {
    const formElement = event.target.closest("form");
    this.element.remove();

    if (formElement) {
      formElement.requestSubmit();
    }
  }
}
