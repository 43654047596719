import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button-selector"
export default class extends Controller {
  static targets = ["radio"];

  selectRadio() {
    this.radioTarget.click()
  }
}
