import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loading-text"
export default class extends Controller {
  static targets = ["label"]
  static values = { copy: String }

  loading(e) {
    this.labelTarget.innerText = this.copyValue
  }
}
