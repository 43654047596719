import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="team-filter"
export default class extends Controller {
  static targets = ["search", "team", "selectall"];

  filter() {
	  const searchText = this.searchTarget.value.toLowerCase().trim();

	  if (searchText.length > 0) {
		  this.selectallTarget.classList.add("hidden")
	  } else {
		  this.selectallTarget.classList.remove("hidden")
	  }
    this.teamTargets.forEach((team) => {
      const teamName = team.querySelector('.team-name').textContent.toLowerCase();
      team.style.display = teamName.includes(searchText) ? '' : 'none';
    });
  }
}
