import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["thumbnail", "container", "spinner"];

  static values = {
    url: String,
    linkUrl: String,
    refreshInterval: Number,
  };

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  refresh() {
    fetch(this.urlValue, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.thumbnailGenerating) {
          this.stopRefreshing();
          this.thumbnailTarget.classList.remove("animate-ping");
          this.thumbnailTarget.src = data.thumbnailUrl;
          this.spinnerTarget.classList.add("hidden")
          this.thumbnailTarget.classList.remove("hidden");
          this.element.href = `${this.linkUrlValue}&thumbnailUrl=${data.thumbnailUrl}`;
          this.thumbnailTarget.classList.add("rounded");
          this.containerTarget.classList.remove("rounded");
          this.containerTarget.classList.remove("bg-white");
        }
      });
  }

  connect() {
    if (this.hasRefreshIntervalValue) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }
}
