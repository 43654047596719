import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cursor-position"
export default class extends Controller {
  connect() {
    const inputElement = this.element;
    const length = inputElement.value.length;

    // Focus the input element
    inputElement.focus();

    // Set cursor position to the end
    inputElement.setSelectionRange(length, length);
  }
}
