import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="eyedropper"
export default class extends Controller {
  connect() {
    if (!window.EyeDropper) {
      this.element.remove()
    }
  }

  click(e) {
    e.preventDefault();
    const eyeDropper = new EyeDropper();
    const abortController = new AbortController();

    eyeDropper
      .open({ signal: abortController.signal })
      .then((result) =>  this.element.dispatchEvent(new CustomEvent('color-picked', {detail: result.sRGBHex})))
      .catch(error => {
        console.error("Error accessing eye dropper:", error);
      });

    setTimeout(() => {
      abortController.abort();
    }, 2000);
  }
}
