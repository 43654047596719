import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="easil-file-upload"
export default class extends Controller {
  static targets = ["files", "btnUpload", "spinner", "filelist", "folder", "donebutton"]
  static values = {
    team: String,
    company: String
  }

  connect() {
    this.btnUploadTarget.classList.add("hidden")
  }

  cancel(e) {
    e.preventDefault()
    this.btnUploadTarget.classList.add("hidden")
    this.filelistTarget.innerHTML = ""
    this.filesTarget.classList.remove('hidden')
    this.btnUploadTarget.innerHTML = "Upload"
    this.btnUploadTarget.removeAttribute("disabled")
  }

  upload(e) {
    e.preventDefault()
    this.btnUploadTarget.innerHTML = "Uploading..."
    this.btnUploadTarget.setAttribute("disabled", "")

    let spinners = [...this.spinnerTargets]
    spinners.forEach((spinner) => {
      spinner.classList.remove("hidden")
    })

    let teamId = this.teamValue;
    let companyId = this.companyValue;

    let files = [...this.filesTarget.files]
    // iterate over the filesTarget and send a turbo stream request to the server
    let fileCount = files.length
    let doneCount = 0

    files.forEach((file) => {
      // find row with id of file.hash
      let row = document.getElementById(file.hash)
      // grab out the aws url
      let aws_url = row.dataset.awsUrl
      let file_id = row.dataset.fileId
      let fileName = row.dataset.fileName

      // post the file to aws
      const params = {
        method: 'PUT',
        body: file
      }

      let postParams;

      fetch(aws_url, params)
        .then(response => {
          if (response.status == 200) {

            let data = {
              filename: fileName,
              id: file_id,
              folderId: file.folderId,
              size: file.size,
              duration: 3000
            }

            postParams = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Accept": "text/vnd.turbo-stream.html",
                'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
              },
              credentials: 'same-origin',
              body: JSON.stringify(data)
            }

            let url = ""

            if (file.type.startsWith("video/") || file.type.startsWith("image/gif")) {
              url = `/${companyId}/teams/${teamId}/diy/v2/uploads/videos`
            } else {
              url = `/${companyId}/teams/${teamId}/diy/v2/uploads/images`
            }

            fetch(url, postParams)
              .then(response => {
                if (response.status == 200) {
                  row.getElementsByClassName("spinicon")[0].classList.add("hidden")
                  row.getElementsByClassName("doneicon")[0].classList.remove("hidden")
                  doneCount += 1
                  if (doneCount == fileCount) {
                    this.btnUploadTarget.classList.add("hidden")
                    this.donebuttonTarget.classList.remove("hidden")
                  }
                }
              })
        }})
    })
  }

  change() {
    this.filesTarget.classList.add('hidden')
    this.btnUploadTarget.classList.add("hidden")

    // remove everything in the imagelist div
    let files = [...this.filesTarget.files]
    // iterate over the filesTarget and send a turbo stream request to the server

    let folderId = ""
    this.folderTargets.forEach((el) => {
      if (el.checked) {
        folderId = el.value
      }
    })

    let teamId = this.teamValue;
    let companyId = this.companyValue;

    let fileCount = files.length
    let doneCount = 0

    files.forEach((file) => {
      var array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      file.folderId = folderId

      let data = {
        filename: file.name,
        size: file.size,
        responseContentType: file.type,
        folderId: folderId,
        hash: array[0]
      }

      file.hash = array[0]

      const params = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/vnd.turbo-stream.html",
          'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
        },
        credentials: 'same-origin',
        body: JSON.stringify(data)
      }

      // file.type.startsWith("video/")

      fetch(`/${companyId}/teams/${teamId}/diy/v2/uploads/url`, params)
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
          doneCount += 1
          if (doneCount == fileCount) {
            this.btnUploadTarget.classList.remove("hidden")
          }
        })

    })

  }
}
