import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="popup-menu"
export default class extends Controller {
  connect() {
    useClickOutside(this, {
      element: this.element
    });
  }

  clickOutside(event) {
    if (!this.element.open) return;
    event.stopPropagation();
    this.close(event)
  }

  close(event) {
    if (this.element.open) {
      this.element.open = false;
    }
    if (event) {
      event.preventDefault();
    }
    this.element.dispatchEvent(new Event('close'));
  }
}
