import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame", "modal"];

  connect() {
    // // The class we should toggle on the container
    // this.toggleClass = this.data.get("class") || "hidden";
    // // The HTML for the background element
    this.backgroundHtml =
      this.data.get("backgroundHtml") || this._backgroundHTML();
    // // The ID of the background to hide/remove
    this.backgroundId = this.data.get("backgroundId") || "modal-background";
    // // Let the user close the modal by clicking on the background
    this.allowBackgroundClose =
      (this.data.get("allowBackgroundClose") || "true") === "true";
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e.preventDefault();
    this.frameTarget.src = e.currentTarget.dataset.url;
    this.modalTarget.classList.add("flex");
    this.modalTarget.classList.remove("hidden");
    e.target.blur();
    // // Lock the scroll and save current scroll position
    this.lockScroll();
    document.documentElement.scrollTop = 0;
    // // Insert the background
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML("beforeend", this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  close(e) {
    if (e) e.preventDefault();
    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();
    // Hide the modal
    this.modalTarget.classList.add("hidden");
    this.modalTarget.classList.remove("flex");
    this.frameTarget.src = null;
    this.frameTarget.innerHTML =
      '<svg class="animate-spin w-12 h-12 block mx-auto m-32 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>';

    // Remove the background
    if (this.background) {
      this.background.remove();
    }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.modalTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (
      e.keyCode === 27 &&
      !this.modalTarget.classList.contains(this.toggleClass)
    ) {
      this.close(e);
    }
  }

  _backgroundHTML() {
    return '<div id="modal-background" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>';
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    // Save the scroll position
    this.saveScrollPosition();
    // Add classes to body to fix its position
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");
    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;
    // Remove classes from body to unfix position
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");
    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();
    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
