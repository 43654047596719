import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  click(e) {
    e.preventDefault();
    e.currentTarget.parentElement.classList.add("active");
    e.currentTarget.nextElementSibling.classList.remove("tw-hidden");
  }
}
