import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["total"];

  input(e) {
    let price = this.element.dataset.price;
    let qty = e.currentTarget.value;
    let total = price * qty;
    this.totalTarget.textContent = `$${total.toFixed(2)}`;
    this.totalTarget.dataset.amount = total;

    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("product-row-changed", true, true, null);
    this.element.dispatchEvent(event);
  }
}
