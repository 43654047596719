import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    text: String,
    element: String
  };

  connect() {
    const tooltipOptions = {
      theme: "material",
      animation: "scale-extreme",
      content: this.textValue,
      ...(this.elementValue && { appendTo: document.getElementById(this.elementValue) })
    };

    tippy(this.element, tooltipOptions);
  }
}
