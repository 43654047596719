import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core";
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="design-flyout"
export default class extends Controller {
  static targets = ["popup", "actionmenu", "spinner"]

  connect() {
    useClickOutside(this, {
      element: this.element
    });
    this.popper = null;
  }

  clickOutside() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
    this.actionmenuTarget.innerHTML = "";
    this.popupTarget.classList.add("tw-hidden");
  }

  toggle() {
    if(!this.popper) {
      let currentSrc = this.actionmenuTarget.dataset.originalSrc
      this.actionmenuTarget.setAttribute("src", currentSrc)

      this.popper = createPopper(this.element, this.popupTarget, {
        strategy: "fixed",
        placement: "right-end",
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['left-end'],
            }
          },
          {
            name: 'offset',
            options: {
              offset: [-20, -16],
            },
          },
        ],
      });
      this.popupTarget.classList.remove("tw-hidden");
    } else {
      this.popper.destroy();
      this.popper = null;
      this.actionmenuTarget.innerHTML = "";
      this.popupTarget.classList.add("tw-hidden");
    }
  }
}
