import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subtotal", "total"];

  change() {
    var sumAmount = this.subtotalTargets.reduce(function (sum, x) {
      let amt = parseFloat(x.textContent.replace(/\$/, "").replace(/,/, ""));
      if (!Number.isNaN(amt)) {
        return sum + amt;
      } else {
        return sum + 0;
      }
    }, 0);

    this.totalTarget.textContent = `$${sumAmount.toFixed(2)}`;
  }
}
