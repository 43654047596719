import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-upload-list"
export default class extends Controller {
  static outlets = ["master"]
  static targets = ["item"]

  selected() {
    this.masterOutlet.blah()
  }
}
