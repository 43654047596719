import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="font-previewer"
export default class extends Controller {
  static targets = [ "preview", "name" ]
  static values = { default: String }

  change() {
    let trimmedValue = this.nameTarget.value.trim();

    if (trimmedValue.length > 0) {
      this.previewTarget.innerHTML = this.nameTarget.value
    } else {
      this.previewTarget.innerHTML = this.defaultValue
    }
  }
}
