import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="download-queue"
export default class extends Controller {
  static targets = ["link"]
  timeoutId = null

  connect() {
    this.timeoutId = setTimeout(() => {
      this.downloadFile();
    }, 2000);
  }

  disconnect() {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  downloadFile() {
    this.linkTarget.click()
  }
}
