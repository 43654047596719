import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-slider"
export default class extends Controller {
  static targets = ["container"]
  static values = { delay: Number }

  connect() {
    this.sliding = false
    this.currentIndex = 0
    this.updateDimensions()
    this.debounceTimer = null
  }

  updateDimensions() {
    this.containerWidth = this.element.offsetWidth
    this.slideWidth = this.containerWidth * 0.95 // 90% + 5% margin
  }

  startSliding() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      if (!this.sliding) {
        this.sliding = true
        this.updateDimensions()
        this.slideToNext()
      }
    }, 50) // 50ms debounce
  }

  stopSliding() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      this.sliding = false
      this.cancelScheduledSlide()
    }, 50) // 50ms debounce
  }

  slideToNext() {
    if (!this.sliding) return

    this.currentIndex = (this.currentIndex + 1) % this.containerTarget.children.length
    const scrollAmount = this.currentIndex * this.slideWidth

    this.element.scrollTo({
      left: scrollAmount,
      behavior: 'smooth'
    })

    this.scheduleNextSlide()
  }

  scheduleNextSlide() {
    this.cancelScheduledSlide()
    if (this.sliding) {
      this.timeout = setTimeout(() => {
        this.slideToNext()
      }, this.delayValue)
    }
  }

  cancelScheduledSlide() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}
