import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    this.updateCountdown()
    this.interval = setInterval(() => this.updateCountdown(), 1000)
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  updateCountdown() {
    const now = new Date().getTime()
    const expirationTime = parseInt(this.element.dataset.countdownValue)
    const timeLeft = expirationTime - now

    if (timeLeft < 0) {
      this.outputTarget.textContent = 'Expired'
    } else {
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)

      this.outputTarget.textContent = `Expires in: ${days}d ${hours}h ${minutes}m ${seconds}s`
    }
  }
}
