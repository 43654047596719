import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="move-dialog"
export default class extends Controller {
  static targets = ["count"];

  static values = {
    count: Number,
    singular: String,
    plural: String,
  };

  countValueChanged() {
    if (this.countValue > 1) {
      this.countTargets.map(
        (e) => (e.innerHTML = `${this.countValue} ${this.pluralValue}`)
      );
    } else {
      this.countTargets.map(
        (e) => (e.innerHTML = `${this.countValue} ${this.singularValue}`)
      );
    }
  }
}
