import Lightbox from '@stimulus-components/lightbox'

export default class extends Lightbox {
  connect() {
    super.connect()

    // Get the lightgallery instance
    this.lightGallery
    // Default options for every lightboxes.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      licenseKey: "DB9C5DED-1214-4210-9D95-329D638A8CDA",
      download: false,
      counter: false,
      backdropDuration: 0
      // Your default options here
    }
  }
}
