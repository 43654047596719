import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-share-item"
export default class extends Controller {
  static targets = ["form"]

  connect() {
  }

  remove() {
    // this.element.style.setProperty('--animate-duration', '0.4s');
    // this.element.classList.add('animate__animated', 'animate__backOutRight');

    // this.element.addEventListener('animationend', () => {
    //   this.element.classList.remove('animate__animated', 'animate__backOutRight');
    //   this.element.classList.add('hidden');
    // })

  }

  add(e) {
    e.preventDefault();
    this.element.style.setProperty('--animate-duration', '0.4s');
    this.element.classList.add('animate__animated', 'animate__backOutLeft');

    this.element.addEventListener('animationend', () => {
      this.element.classList.remove('animate__animated', 'animate__backOutLeft');
      this.element.classList.add('hidden');
      this.formTarget.requestSubmit();
    })
  }
}
