import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    if (this.hasInputTarget) {
      this.setInputAttributes();
      this.update();
    }
  }

  update() {
    this.inputTarget.style.height = "auto";
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
  }

  setInputAttributes() {
    this.inputTarget.setAttribute(
      "style",
      `height: ${this.inputTarget}px; overflow-y: hidden;min-height: auto;`
    );
  }
}
