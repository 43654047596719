import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="detailopener"
export default class extends Controller {
  open() {
    this.element.open = true
  }

  close() {
    this.element.open = false
  }
}
