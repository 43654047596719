import { Controller } from "@hotwired/stimulus";

/*
  Modal showing a catalogue modal with relevant order/edit options
*/
export default class extends Controller {
  static targets = [
    // "assistable",
    "blurable",
    "deleteable",
    "diy",
    "downloadable",
    "modal",
    "orderable",
    "subtitle",
    "thumbnail",
    "title",
    // "customizable",
    // "formcustomize",
    "formdiy",
    "diyable",
    "hideOnSpinner",
    "admineditbar",
    "adminediturl",
    "editbuttontext",
    "actionBar",
  ];

  // Show the modal and fill card content
  show(e) {
    e.preventDefault();

    this.fillContent(e.currentTarget.dataset);
    this.showModal();
  }

  // Close/hide the modal
  close(e) {
    if (e.currentTarget.classList.contains("popper-close")) {
      e.preventDefault();
    }

    // grab targets
    const blurables = this.blurableTargets;
    const modal = this.modalTarget;

    // remove blur from blurrable elements
    Array.from(blurables, (e) => (e.style.filter = "none"));

    // hide the modal
    modal.style.opacity = 0;
    modal.classList.add("hide");
  }

  fillContent(content) {
    // grab targets
    const thumbnail = this.thumbnailTarget;
    const title = this.titleTarget;
    const subtitle = this.subtitleTarget;
    const diyBadge = this.diyTarget;

    // const assistable = this.assistableTarget;
    const downloadable = this.downloadableTarget;
    const orderable = this.orderableTarget;
    const deleteable = this.deleteableTarget;
    // const customizable = this.customizableTarget;
    // const formcustomize = this.formcustomizeTarget;
    const formdiy = this.formdiyTarget;
    const diyable = this.diyableTarget;
    const admineditbar = this.admineditbarTarget;
    const adminediturl = this.adminediturlTarget;
    const editbuttontext = this.editbuttontextTarget;

    const isDiy = content.showDiyLabel === "true";
    // const isAssistable = content.assistable === "true";
    const isDownloadable = content.downloadable === "true";
    const isOrderable = content.orderable === "true";
    const isDeleteable = content.deleteable === "true";
    // const isCustomizable = content.customizable === "true";
    const isDiyDraft = content.diydraft === "true";
    const companyId = content.companyid;
    const productId = content.id;
    const isCartable = content.cart === "true";
    const isDiyRemix = content.diy_remix === "true";
    const cartUrl = content.cartUrl;
    const adminEditUrl = content.adminEditUrl;
    const editButtonUrl = content.editButtonUrl;
    const editButtonText = content.editButtonText;
    const deleteUrl = content.deleteUrl;

    const actionable = cartUrl || deleteUrl || isDownloadable;

    // set textual content
    if (content.image) {
      thumbnail.src = content.image;
    }
    title.innerHTML = content.title;
    subtitle.innerHTML = content.subtitle;

    if (adminEditUrl) {
      this.toggleElementVisibility(admineditbar, true);
      adminediturl.href = adminEditUrl;
    } else {
      this.toggleElementVisibility(admineditbar, false);
    }

    if (editButtonUrl) {
      formdiy.href = editButtonUrl;
      editbuttontext.innerHTML = editButtonText;
      this.toggleElementVisibility(diyable, true);
    } else {
      this.toggleElementVisibility(diyable, false);
    }

    if (deleteUrl) {
      deleteable.href = deleteUrl;
      this.toggleElementVisibility(deleteable, true);
    } else {
      this.toggleElementVisibility(deleteable, false);
    }

    this.toggleElementVisibility(this.actionBarTarget, actionable);

    // if (isDiyRemix) {
    //   formdiy.href =
    //     "/portal/" + companyId + "/products/" + productId + "/easil_link";
    // } else if (isDiyDraft) {
    //   formdiy.href =
    //     "/portal/" +
    //     companyId +
    //     "/products/" +
    //     productId +
    //     "/easil_link?easil_continue=1";
    // } else {
    //   formdiy.href =
    //     "/portal/" +
    //     companyId +
    //     "/products/" +
    //     productId +
    //     "/easil_link?easil_direct=1";
    // }

    if (cartUrl) {
      orderable.href = cartUrl;
      this.toggleElementVisibility(orderable, true);
    } else {
      this.toggleElementVisibility(orderable, false);
    }

    if (isDownloadable) {
      downloadable.href =
        "/" + companyId + "/diy/designs/" + productId + "/download";
    }

    // formcustomize.action =
    //   "/portal/" + companyId + "/products/" + productId + "/customise";

    // toggle visibility of elements based on state
    this.toggleElementVisibility(diyBadge, isDiy);
    // this.toggleElementVisibility(diyable, isDiy);
    // this.toggleElementVisibility(assistable, isAssistable);
    this.toggleElementVisibility(downloadable, isDownloadable);
    // this.toggleElementVisibility(deleteable, isDeleteable);
    // this.toggleElementVisibility(customizable, isCustomizable);
  }

  showModal() {
    // grab targets
    const blurables = this.blurableTargets;
    const modal = this.modalTarget;

    // blur elements
    Array.from(blurables, (e) => (e.style.filter = "blur(7px) grayscale(1)"));

    // show the modal
    modal.style.opacity = 1;
    modal.classList.remove("hide");
  }

  // toggle `element` visibility based on if parameter `show` is true or not
  toggleElementVisibility(element, show) {
    if (show) {
      element.classList.remove("hide");
    } else {
      element.classList.add("hide");
    }
  }
}
