import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountSelect", "teamSelect", "teamSelectContainer"]

  connect() {
    this.filterTeams()
  }

  filterTeams() {
    const selectedAccountId = parseInt(this.accountSelectTarget.value)

    if (isNaN(selectedAccountId)) {
      this.hideTeamSelect()
      return
    }

    const teamsData = JSON.parse(this.accountSelectTarget.dataset.teams)
    const selectedAccount = teamsData.find(account => account.easil_user_account_id === selectedAccountId)

    if (selectedAccount && selectedAccount.teams && selectedAccount.teams.length > 0) {
      this.populateTeamSelect(selectedAccount.teams)
      this.showTeamSelect()
    } else {
      this.hideTeamSelect()
    }
  }

  populateTeamSelect(teams) {
    const teamOptions = this.flattenTeams(teams)
    this.teamSelectTarget.innerHTML = teamOptions.map(team =>
      `<option value="${team.easil_user_team_id || team.team_id}">${'-'.repeat(team.depth || 0)} ${team.name}</option>`
    ).join('')
  }

  showTeamSelect() {
    this.teamSelectContainerTarget.style.display = 'block'
  }

  hideTeamSelect() {
    this.teamSelectContainerTarget.style.display = 'none'
    this.teamSelectTarget.innerHTML = '<option value="">Select a team</option>'
  }

  flattenTeams(teams, depth = 0) {
    return teams.flatMap(team => [
      { ...team, depth },
      ...(this.flattenTeams(team.subteams || [], depth + 1))
    ])
  }
}
