import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diy-move-design"
export default class extends Controller {
  static outlets = ["masonry"]

  static values = {
    folder: String,
    collection: String
  }

  connect() {
    this.masonryOutlet.remove(this.collectionValue, this.folderValue)
  }
}
