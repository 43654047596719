import { Controller } from "@hotwired/stimulus";

import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import flatpickr from "flatpickr";
//import { atRule } from "postcss";

export default class extends Controller {
  connect() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const inOneYear = new Date(today);
    inOneYear.setDate(inOneYear.getDate() + 365);
    const tomorrownext = new Date(today);
    tomorrownext.setDate(tomorrownext.getDate() + 2);
    const tomorrownextten = new Date(today);
    tomorrownextten.setDate(tomorrownextten.getDate() + 10);

    flatpickr(".flatpickr-month-select", {
      wrap: true,
      plugins: [
        new monthSelectPlugin({
          theme: "airbnb",
          altFormat: "Y-m-01",
          dateFormat: "Y-m-01",
        }),
      ],
      onChange: (_, dateStr, instance) => {
        document.getElementById("month").value = dateStr;
        let form = document.getElementById("filter-form");
        form.submit();
        instance.close();
      },
    });

    flatpickr(".flatpickr-event-time", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:iK",
      minuteIncrement: 15,
    });

    flatpickr(".flatpickr-event-time-minute", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:iK",
      minuteIncrement: 1,
    });

    flatpickr(".flatpickr-date-time", {
      enableTime: true,
      dateFormat: "M d, Y h:iK",
    });

    flatpickr(".flatpickr-event-date", {
      dateFormat: "d/m/y",
      minDate: tomorrow,
      maxDate: inOneYear,
    });

    flatpickr(".flatpickr-event-date-promo", {
      dateFormat: "d/m/Y",
      maxDate: inOneYear,
    });

    flatpickr(".dppromostart", {
      altFormat: "d/m/Y",
      altInput: true,
    });

    flatpickr(".dpexpirydate", {
      altFormat: "d/m/Y",
      altInput: true,
      maxDate: inOneYear,
    });

    flatpickr(".dpfirstproof", {
      dateFormat: "d/m/Y",
      minDate: tomorrownext,
      disable: [
        function (date) {
          return date.getDay() === 0 || date.getDay() === 6;
        },
      ],
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
    });

    flatpickr(".dpfirstproofrfg", {
      dateFormat: "d/m/Y",
      minDate: tomorrownextten,
      disable: [
        function (date) {
          return date.getDay() === 0 || date.getDay() === 6;
        },
      ],
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
    });
  }
}
