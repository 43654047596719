import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

// Connects to data-controller="tagger"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.taggify = new Tagify(this.inputTarget, {
      editTags: false,
    })
    this.taggify.DOM.scope.style.setProperty('--tags-border-color', 'white');
  }
}
