import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog-opener"
export default class extends Controller {
  static outlets = ["dialog"]

  open(e) {
    e.preventDefault();
    this.dialogOutlet.open()
  }
}
