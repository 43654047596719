import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  category_select(e) {
    e.preventDefault();
    let category = e.currentTarget.dataset.diySizePickerCategoryValue;

    Array.from(document.getElementsByClassName("category-select")).forEach(
      (e) => {
        e.classList.remove("active:text-blue-500");
        e.classList.remove("focus:text-blue-500");
        e.classList.remove("text-blue-500");
        e.classList.remove("font-bold");
        e.classList.add("active:text-gray-500");
        e.classList.add("focus:text-gray-500");
        e.classList.add("text-gray-500");
        e.classList.add("font-normal");
      }
    );

    e.currentTarget.classList.add("active:text-blue-500");
    e.currentTarget.classList.add("focus:text-blue-500");
    e.currentTarget.classList.add("text-blue-500");
    e.currentTarget.classList.add("font-normal");

    Array.from(document.getElementsByClassName("category_items")).forEach(
      (e) => {
        e.classList.add("hidden");
      }
    );

    document.getElementById(category).classList.remove("hidden");
  }
}
