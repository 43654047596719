import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button-enabler"
export default class extends Controller {
  static targets = ["submit"]

  enableSubmit() {
    this.submitTarget.disabled = !this._anyRadioButtonSelected();
  }

  _anyRadioButtonSelected() {
    return this.element.querySelector('input[type="radio"]:checked') !== null;
  }
}
