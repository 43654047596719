import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "menu", "overlay"];

  open() {
    this.sidebarTarget.classList.remove("tw-hidden");
    document.getElementById("mobile-sidebar-container").scrollTop = 0;
  }

  close() {
    this.sidebarTarget.classList.add("tw-hidden");
    window.scrollTo(0, 0);
  }
}
