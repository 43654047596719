import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["downloadbutton", "link", "messageButton"];

  pollDesignDownload(location, token) {
    fetch(location, {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseStatus) => {
        if (!["FAILED", "COMPLETED"].includes(responseStatus.status)) {
          setTimeout(() => {
            this.pollDesignDownload(location, token);
          }, 3000);
        } else if (responseStatus.status == "FAILED") {
          this.downloadbuttonTarget.classList.remove("hidden");
          this.messageButtonTarget.classList.add("hidden");
        } else {
          this.linkTarget.href = responseStatus.location;
          this.downloadbuttonTarget.classList.remove("hidden");
          this.messageButtonTarget.classList.add("hidden");
          this.linkTarget.click();
        }
      });
  }

  render(e) {
    e.preventDefault();

    const meta = document.querySelector("meta[name=csrf-token]");

    let meta_content = "";

    if (meta) {
      meta_content = meta.content;
    }

    this.downloadbuttonTarget.classList.add("hidden");
    this.messageButtonTarget.classList.remove("hidden");

    fetch(this.element.dataset.url, {
      method: "POST",
      headers: {
        Accept:
          "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01",
        "X-CSRF-Token": meta_content,
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        if (!response.ok) {
          this.downloadbuttonTarget.classList.remove("hidden");
          this.messageButtonTarget.classList.add("hidden");
        } else {
          return response.json();
        }
      })
      .then((location) => {
        this.pollDesignDownload(location.location, location.token);
      });
  }
}
