import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  // Sets the popover offset using Stimulus data map objects.
  initialize() {
    this.contentTarget.setAttribute(
      "style",
      `min-width: 300px; transform:translate(${this.data.get(
        "translateX"
      )}, ${this.data.get("translateY")});`
    );
  }

  // Show the popover
  mouseOver() {
    this.contentTarget.classList.remove("tw-hidden");
  }
  // Hide the popover
  mouseOut() {
    this.contentTarget.classList.add("tw-hidden");
  }
}
