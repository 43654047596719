import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="requesteddropdown"
export default class extends Controller {
  static targets = ["dropdown"]

  click(e) {
    e.preventDefault();
    this.dropdownTarget.classList.toggle("tw-hidden")
  }
}
