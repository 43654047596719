import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="darkmode"
export default class extends Controller {
  static targets = ["button", "knob"];

  connect() {
    // if set via local storage previously
    if (localStorage.getItem("color-theme")) {
      if (localStorage.getItem("color-theme") === "light") {
        this.buttonTarget.classList.add("bg-gray-400");
        this.buttonTarget.classList.add("dark:bg-gray-400");
        this.knobTarget.classList.add("translate-x-0");
        this.buttonTarget.classList.remove("bg-blue-600");
        this.buttonTarget.classList.remove("dark:bg-blue-600");
        this.knobTarget.classList.remove("translate-x-5");
      } else {
        this.buttonTarget.classList.remove("bg-gray-400");
        this.buttonTarget.classList.remove("dark:bg-gray-400");
        this.knobTarget.classList.add("translate-x-5");
        this.buttonTarget.classList.add("bg-blue-600");
        this.buttonTarget.classList.add("dark:bg-blue-600");
        this.knobTarget.classList.remove("translate-x-0");
      }
    } else {
      if (document.documentElement.classList.contains("dark")) {
        this.buttonTarget.classList.remove("bg-gray-400");
        this.buttonTarget.classList.remove("dark:bg-gray-400");
        this.knobTarget.classList.remove("translate-x-0");
        this.buttonTarget.classList.add("bg-blue-600");
        this.buttonTarget.classList.add("dark:bg-blue-600");
        this.knobTarget.classList.add("translate-x-5");
      } else {
        this.buttonTarget.classList.add("bg-gray-400");
        this.buttonTarget.classList.add("dark:bg-gray-400");
        this.knobTarget.classList.remove("translate-x-5");
        this.buttonTarget.classList.remove("bg-blue-600");
        this.buttonTarget.classList.remove("dark:bg-blue-600");
        this.knobTarget.classList.add("translate-x-0");
      }
    }
  }

  click() {
    // if set via local storage previously
    if (localStorage.getItem("color-theme")) {
      if (localStorage.getItem("color-theme") === "light") {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
        this.buttonTarget.classList.remove("bg-gray-400");
        this.buttonTarget.classList.remove("dark:bg-gray-400");
        this.knobTarget.classList.remove("translate-x-0");
        this.buttonTarget.classList.add("bg-blue-600");
        this.buttonTarget.classList.add("dark:bg-blue-600");
        this.knobTarget.classList.add("translate-x-5");
      } else {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");

        this.buttonTarget.classList.add("bg-gray-400");
        this.buttonTarget.classList.add("dark:bg-gray-400");
        this.knobTarget.classList.add("translate-x-0");
        this.buttonTarget.classList.remove("bg-blue-600");
        this.buttonTarget.classList.remove("dark:bg-blue-600");
        this.knobTarget.classList.remove("translate-x-5");
      }
    } else {
      if (document.documentElement.classList.contains("dark")) {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");
        this.buttonTarget.classList.add("bg-gray-400");
        this.buttonTarget.classList.add("dark:bg-gray-400");
        this.knobTarget.classList.add("translate-x-0");
        this.buttonTarget.classList.remove("bg-blue-600");
        this.buttonTarget.classList.remove("dark:bg-blue-600");
        this.knobTarget.classList.remove("translate-x-5");
      } else {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
        this.buttonTarget.classList.remove("bg-gray-400");
        this.buttonTarget.classList.remove("dark:bg-gray-400");
        this.knobTarget.classList.remove("translate-x-0");
        this.buttonTarget.classList.add("bg-blue-600");
        this.buttonTarget.classList.add("dark:bg-blue-600");
        this.knobTarget.classList.add("translate-x-5");
      }
    }
  }
}
