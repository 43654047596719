import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="caret-to-end"
export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.moveCaretToEnd()
  }

  update() {
    this.moveCaretToEnd()
  }

  moveCaretToEnd() {
    const val = this.fieldTarget.value
    this.fieldTarget.value = ''
    this.fieldTarget.value = val
  }
}
