import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-form-clear"
export default class extends Controller {
  static targets = ["input", "clearButton", "selectAll", "checkbox"];

  connect() {
      this.updateClearButtonVisibility();
  }

  updateInput() {
      this.updateClearButtonVisibility();
      this.filterLabels();
  }

  clearInput() {
      this.inputTarget.value = '';
      this.updateClearButtonVisibility();
      this.filterLabels();
  }

  updateClearButtonVisibility() {
      if (!this.hasInputTarget) return; // Guard clause no input target
      const inputContent = this.inputTarget.value;
      this.clearButtonTarget.classList.toggle('hidden', inputContent.length === 0);
      this.selectAllTarget.classList.toggle('hidden', inputContent.length !== 0);
  }

  filterLabels() {
    const searchString = this.inputTarget.value.toLowerCase();
    this.checkboxTargets.forEach((checkbox) => {
        const label = checkbox.nextElementSibling;
        if (label && label.textContent.toLowerCase().includes(searchString)) {
            label.style.display = ""; // Show label
        } else {
            label.style.display = "none"; // Hide label
        }
    });
  }
}
