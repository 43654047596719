import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actionrow"];

  click() {
    document
      .querySelectorAll(".order-index-list-action-row")
      .forEach((e) => e.classList.add("tw-hidden"));
    this.actionrowTarget.classList.remove("tw-hidden");
  }
}
