import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="team-color-renamer"
export default class extends Controller {
  static targets = ["name", "field", "edit", "cancel", "save"]

  edit() {
    this.editTarget.classList.add("hidden")
    this.nameTarget.classList.add("hidden")
    this.cancelTarget.classList.remove("hidden")
    this.saveTarget.classList.remove("hidden")
    this.fieldTarget.type = "text"
  }

  cancel() {
    this.editTarget.classList.remove("hidden")
    this.nameTarget.classList.remove("hidden")
    this.cancelTarget.classList.add("hidden")
    this.saveTarget.classList.add("hidden")
    this.fieldTarget.type = "hidden"
  }

  save(event) {
    const formElement = event.target.closest("form");

    if (formElement) {
      formElement.requestSubmit();
    }
  }
}
