import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["main"];

  connect() {
    const endStuff = async () => {
      const categoryOrder = sortable.toArray();
      await fetch(this.data.get("url"), {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "same-origin",
        body: JSON.stringify({ order: categoryOrder }),
      });
    };

    const sortable = Sortable.create(this.mainTarget, { onEnd: endStuff });
  }
}
