import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="easilprint"
export default class extends Controller {
  static targets = ["iframe"];

  click(e) {
    e.preventDefault();
    this.iframeTarget.src = this.iframeTarget.dataset.url;
  }
}
