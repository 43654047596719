import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-snap"
export default class extends Controller {
  static targets = ["dot"]

  connect() {
    this.observeImages()
    this.addScrollListener()
  }

  observeImages() {
    const options = {
      root: this.element,
      rootMargin: '0px',
      threshold: 0.5  // Changed back to 0.5 for smoother detection
    }

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(this.element.querySelectorAll('.snap-center')).indexOf(entry.target)
          this.highlightDot(index)
        }
      })
    }, options)

    this.element.querySelectorAll('.snap-center').forEach((image) => {
      this.observer.observe(image)
    })
  }

  addScrollListener() {
    this.element.addEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    clearTimeout(this.scrollTimeout)
    this.scrollTimeout = setTimeout(() => {
      const scrollLeft = this.element.scrollLeft
      const slideWidth = this.element.querySelector('.snap-center').offsetWidth
      const index = Math.round(scrollLeft / slideWidth)
      this.highlightDot(index)
    }, 100)
  }

  highlightDot(index) {
    this.dotTargets.forEach((dot, i) => {
      if (i === index) {
        dot.classList.add('bg-blue-500')
        dot.classList.remove('bg-gray-300')
      } else {
        dot.classList.add('bg-gray-300')
        dot.classList.remove('bg-blue-500')
      }
    })
  }

  disconnect() {
    this.observer.disconnect()
    this.element.removeEventListener('scroll', this.handleScroll.bind(this))
  }
}
